import React from "react"
import Layout from "../components/Layout"

const NotFound = () => (
  <Layout>
    <div className="notFound">
      <h3>Oops, 404. Olmayan bir sayfayı tuşladınız. </h3>
    </div>
  </Layout>
)

export default NotFound;
